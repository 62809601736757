<template>
  <div >
  <v-main class="container-prosker d-flex flex-column h-100">
    <v-slide-group class="pa-4" show-arrows v-model="model">
      <v-slide-item
        v-for="(category, i) in categoriesData"
        :key="i"
        v-slot:default="{ active, toggle }"
      >
        <v-card
          class="my-2 mr-15 py-1 border-radius-medium"
          :width="$vuetify.breakpoint.mobile ? 210 : 250"
          :class="active ? 'title_highlight_color text-white' : 'bg-transparent hint_text_color--text'"
          :elevation="active ? 2 : 0"
          @click="active ? '' : changeSubcategory(category, i)"
        >
          <div class="w-100 d-table h-100 ">
            <div class="d-table-cell align-middle">
              <v-row align="center" justify="center">
                <v-col cols="2" class="mr-3">
                  <v-avatar color="white" size="40">
                    <v-img :src="category.img_url" :alt="category.name" contain/>
                  </v-avatar>
                </v-col>
                <v-col cols="8">
                  <span class="font-weight-bold mt-1 font-size-14">
                    {{ category.name }}
                  </span>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <h1 class="secondary--text">Profesionales</h1>
    <v-main class="px-10">
      <v-row v-if="loading">
          <v-col v-for="index in 6" :key="index" cols="6" xl="2" md="3">
              <v-skeleton-loader
                      v-bind="skeletonAttrs"
                      type="image, divider, list-item"
              ></v-skeleton-loader>
          </v-col>
      </v-row>
      <v-row v-show="!loading && categorySelected">
      <v-col class="text-center" cols="6" xl="2" md="3">
        <v-card  class="border-radius-medium " elevation="0">
          <v-card-text class="center-mobile">
            <v-row class="justify-content-center img-container">
              <img
                  :src="categorySelected.img_url"
                  class="pointer-cursor container img-fluid"
                  :alt="categorySelected.name"
                  @click="sendGlobalSearch(categorySelected.children.concat([{ id: categorySelected.id }]))"
                  @load="loadImage"
              />
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-content-center pb-0 pt-3 bg-prosk-secondary">
            <p class="font-weight-bold white--text" @click="sendGlobalSearch(categorySelected.children.concat([{ id: categorySelected.id }]))">
              {{ categorySelected.name }}
            </p>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        class="text-center"
        cols="6"
        xl="2"
        md="3"
        v-for="(subItem, i) in categorySelected.children"
        :key="i"
      >
        <v-card class="border-radius-medium " elevation="0">
          <v-card-text class="center-mobile">
            <v-row class="justify-content-center img-container">
                <img
                    :src="subItem.img_url"
                    class="pointer-cursor container img-fluid"
                    :alt="subItem.name"
                    @click="sendGlobalSearch([subItem])"
                    @load="loadImage"
                />
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-content-center pb-0 pt-3 main_text_color--text">
            <p class="font-weight-bold " @click="sendGlobalSearch([subItem])">
              {{ subItem.name }}
            </p>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
      </v-main>
  </v-main>
</div>
</template>
<script>
import axios from 'axios';
import CryptoJS from 'crypto-js';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { AES_SECRET } from '@/misc/constants';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  name: 'Categories',
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      model: 0,
      imagesLoaded: 0,
      loading: false,
      errors: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      globalSearchObject: {},
      categoriesData: [],
      categorySelected: null
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.setImportedConstants();
    this.getCategoriesData();
  },
  methods: {
    setImportedConstants () {
      this.$options.AES_SECRET = AES_SECRET;
    },
    sendGlobalSearch (categories) {
      this.globalSearchObject.user = null;
      this.globalSearchObject.categoryIds = [];
      categories.forEach((category) => {
        this.globalSearchObject.categoryIds.push(CryptoJS.AES.encrypt(category.id.toString(), this.$options.AES_SECRET).toString());
      });
      this.$router.push({ path: '/perfiles', query: { categoryIds: this.globalSearchObject.categoryIds.join(',') } }).then(failure => {
            console.log(failure);
      });
    },
    loadImage () {
        this.imagesLoaded++;
        if (this.imagesLoaded > this.categorySelected.children.length) {
            this.loading = false
        }
    },
    changeSubcategory (item, n) {
      this.loading = true;
      this.model = n;
      this.imagesLoaded = 0;
      this.categorySelected = null;
      const url = `${process.env.VUE_APP_BASE_URL}/api/categories/with-subcategory`;
      axios
        .get(url)
        .then((response) => {
          this.categorySelected = response.data.data.filter(function (
            category
          ) {
            return category.id === item.id;
          })[0];
          this.$router.replace({ path: `/categorias/${this.categorySelected.name.toLowerCase()}` });
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    },
    getCategoriesData () {
      this.loading = true;
      const name = this.$route.params.name;
      const url = `${process.env.VUE_APP_BASE_URL}/api/categories/with-subcategory`;
      axios.get(url).then((response) => {
        response.data.data.forEach((category) => {
          if (!category.parent_id) {
            this.categoriesData.push(category);
          }
        });
        if (name) {
          this.categorySelected = this.categoriesData.find((category) => encodeURIComponent(category.name.toLowerCase()) === encodeURIComponent(name.toLowerCase()));
        }
        if (!this.categorySelected) {
          this.categorySelected = this.categoriesData.find((category) => category.is_default);
        }
        this.categoriesData.sort(function (a, b) {
          if (!a.is_default) {
            return 1;
          }
          if (a.is_default) {
            return -1;
          }
          return 0;
        });
        this.model = this.categoriesData.findIndex((category) => category.id === this.categorySelected.id);
      }).catch((error) => {
        this.showError(error);
      });
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'category'
    });
  }
};
</script>
<style scoped>
  .round {
    border-radius: 100px;
  }

  .img-fluid {
    width: 140px;
    height: 160px;
  }

  .img-container {
    max-height: 260px;
    max-width: 260px;
  }

  .card {
    border-radius: 12px;
  }

  @media(max-width: 1225px) {
    .img-fluid {
      width: 100px;
      height: 120px;
      margin: 0 auto;
    }

    .img-container {
      max-height: 150px;
      max-width: 150px;
    }

    .center-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card-mobile {
      max-height: 180px;
      max-width: 180px;
    }
  }
</style>
